<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_measurements') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton ? true : false"
        @click="submitMeasurement()"
      >{{ $t('message.save') }}</el-button>
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        @click="submitMeasurement(true)"
      >{{ $t('message.save_and_create_new') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close()">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" :rules="rules" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="columns.name.title" prop="name">
              <el-input v-model="form.name" :placeholder="columns.name.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="columns.full_name.title" prop="full_name">
              <el-input
                v-model="form.full_name"
                :placeholder="columns.full_name.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="columns.code.title" prop="code">
              <el-input v-model="form.code" :placeholder="columns.code.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="columns.status.title" prop="status">
              <el-select v-model="form.status" :placeholder="columns.status.title">
                <el-option value="active">{{ $t('message.active') }}</el-option>
                <el-option value="in_active">{{ $t('message.not_active') }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
export default {
  mixins: [drawerChild, form],
  computed: {
    ...mapGetters({
      model: "measurements/model",
      rules: "measurements/rules",
      columns: "measurements/columns"
    })
  },
  methods: {
    ...mapActions({
      save: "measurements/store",
      update: "measurements/update",
      editModel: "measurements/show",
      empty: "measurements/empty"
    }),
    submitMeasurement(resetForm = false) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingButton = true;
          if (this.form.id == null) {
            this.save(this.form)
              .then(res => {
                this.loadingButton = false;
                this.form = res.data.measurement;
                this.$alert(res);
              })
              .catch(err => {
                this.$alert(err);
                this.loadingButton = false;
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          } else {
            this.update(this.form)
              .then(res => {
                this.loadingButton = false;
                this.$alert(res);
              })
              .catch(err => {
                this.loadingButton = false;
                this.$alert(err);
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          }
        }
      });
    }
  }
};
</script>
